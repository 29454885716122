import styled from '@emotion/styled'
import { breakpoints } from '../../../utils/styles'

export const Wrapper = styled.div`
  margin-bottom: 40px;
`

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  @media (min-width: ${breakpoints.m}px) {
    max-width: 1000px;
    margin: 0 auto 40px;
  }
`

export const Body = styled.div`
  width: calc(100% - 55px);
`

export const Photo = styled.div`
  @media (min-width: ${breakpoints.m}px) {
    width: 60%;
  }
`

export const TextContainer = styled.div`
  margin: 16px 0 60px 12px;
`

export const Text = styled.div`
  font-size: 15px;
  color: #707070;
  margin-bottom: 30px;

  @media (min-width: ${breakpoints.m}px) {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 50px;
  }
`

export const TextLinkWrapper = styled.div`
  padding-right: 12px;
  @media (min-width: ${breakpoints.m}px) {
    max-width: 1000px;
    margin: 0 auto;
  }
`
