import React from 'react'
import styled from '@emotion/styled'
import SEO from '../components/seo'
import PageTransition from '../components/Animation/PageTransition'
import BrandStory from '../templates/Top/BrandStory'
import TopPageTail from '../templates/Top/TopPageTail'

const ConceptPage = () => {
  return (
    <>
      <SEO
        title="コンセプト"
        keywords={[`高天原`, `アクセサリー`, `天然石`, `accessory`, `stone`]}
      />
      <Wrapper>
        <BrandStory />
        <TopPageTail />
        <PageTransition />
      </Wrapper>
    </>
  )
}

export default ConceptPage

export const Wrapper = styled.div``
