import React from 'react'
import { Container, Photo } from './styles'
import Feature from '../Feature'
import HowToWear from '../HowToWear'
import Choice from '../Choice'
import Image from '../../../components/image'

const TopPageTail = () => {
  return (
    <>
      <Photo>
        <Image filename="photo/shiraito-taki.jpg" />
      </Photo>
      <Container>
        <Feature />
        <HowToWear />
        <Choice />
      </Container>
    </>
  )
}

export default TopPageTail
