import React from 'react'
import TitleTop from '../../../components/Title/TitleTop'
import { Text, Wrapper, Container, TextContainer, Photo } from './styles'
import KanjiTitle from '../../../components/Title/KanjiTitle'
import TextLink from '../../../components/TextLink'
import Image from '../../../components/image'

const HowToWear = () => {
  return (
    <Wrapper>
      <Container>
        <Photo>
          <Image filename="photo/how-to-wear.jpg" />
        </Photo>
        <TextContainer>
          <TitleTop en="HOW TO WEAR" label="身に付け方" />
          <KanjiTitle label="粋" />
          <Text>
            今必要だと感じたら手に取って下さい。 ある物事と向き合う時、
            支えになってくれることでしょう。
          </Text>
          <TextLink label="身に付け方について" link="/howtowear" />
        </TextContainer>
      </Container>
    </Wrapper>
  )
}

export default HowToWear
