import React from 'react'
import TitleTop from '../../../components/Title/TitleTop'
import {
  Text,
  Wrapper,
  Container,
  TextContainer,
  Body,
  TextLinkWrapper,
  Photo,
} from './styles'
import KanjiTitle from '../../../components/Title/KanjiTitle'
import TextLink from '../../../components/TextLink'
import Image from '../../../components/image'

const Choice = () => {
  return (
    <Wrapper>
      <Container>
        <Body>
          <Photo>
            <Image filename="photo/choice-bracelet.jpg" />
          </Photo>
          <TextContainer>
            <TitleTop en="CHOICE" label="選び方" />
            <KanjiTitle label="感" />
            <Text>
              自分の直感を信じてみて下さい。 ご縁のある石が不思議と分かります。
              また、ストーンリーディング（石選び）も出来ますので
              その際はお問い合わせ下さい。
            </Text>
          </TextContainer>
        </Body>
      </Container>
      <TextLinkWrapper>
        <TextLink label="選び方について" link="/choice" />
      </TextLinkWrapper>
    </Wrapper>
  )
}

export default Choice
