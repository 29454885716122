import React from 'react'
import TitleTop from '../../../components/Title/TitleTop'
import { Text, Wrapper, Photo, TitleWrapper, Container } from './styles'
import KanjiTitle from '../../../components/Title/KanjiTitle'
import TextLink from '../../../components/TextLink'
import Image from '../../../components/image'

const BrandStory = () => {
  return (
    <Wrapper>
      <Container>
        <TitleWrapper>
          <TitleTop
            en="BRAND STORY"
            label="ブランドストーリー"
            isLine={false}
          />
        </TitleWrapper>
        <KanjiTitle label="縁" />
        <Text>
          高天原のテーマである「ご縁・繋がり」。
          <br />
          目に見えない大切な想いをアクセサリーを通して表現し、三方良しの商いを後世に伝え、広めていく事を目的とします。
        </Text>
        <Photo>
          <Image filename="photo/site-image.jpg" />
        </Photo>
        <TextLink label="ブランドストーリーを見る" link="/brandstory" />
      </Container>
    </Wrapper>
  )
}

export default BrandStory
