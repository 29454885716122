import styled from '@emotion/styled'
import bg from '../../../images/bg/bg_tail.jpg'
import { breakpoints } from '../../../utils/styles'

export const Photo = styled.div`
  @media (min-width: ${breakpoints.m}px) {
    width: 800px;
    max-width: 1000px;
    margin: 0 auto;
  }
`

export const Container = styled.div`
  background-image: url(${bg});
  background-size: 100% 105%;
`

export const TitleWrapper = styled.div`
  margin-bottom: 16px;
`

export const Text = styled.div`
  font-size: 15px;
  color: #707070;
  margin-bottom: 30px;
`
