import React from 'react'
import TitleTop from '../../../components/Title/TitleTop'
import { Text, Container, Wrapper } from './styles'
import KanjiTitle from '../../../components/Title/KanjiTitle'
import TextLink from '../../../components/TextLink'

const Feature = () => {
  return (
    <Wrapper>
      <Container>
        <TitleTop en="FEATURE" label="こだわり" />
        <KanjiTitle label="義" />
        <Text>
          持ち主の意志や信念を正しい方向へと導いて欲しい。
          <br />
          生まれ持った賜物、魅力を高める為、
          石の浄化、自然のエネルギーを取り入れています。
        </Text>
        <TextLink label="こだわりについて" link="/feature" />
      </Container>
    </Wrapper>
  )
}

export default Feature
