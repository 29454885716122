import styled from '@emotion/styled'
import { breakpoints } from '../../../utils/styles'

export const Wrapper = styled.div`
  padding: 24px 12px 30px;
`

export const Container = styled.div`
  @media (min-width: ${breakpoints.m}px) {
    max-width: 1000px;
    margin: 0 auto 50px;
  }
`

export const TitleWrapper = styled.div`
  margin-bottom: 16px;
`

export const Text = styled.div`
  font-size: 15px;
  color: #707070;
  margin-bottom: 40px;

  @media (min-width: ${breakpoints.m}px) {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 50px;
  }
`

export const Photo = styled.div`
  margin-bottom: 16px;
`
